import { z } from 'zod';

import { paginationMetaSchema } from './shared-schema';

//todo: remove transferRateType
export const transferRateType = z.enum([
  'points_transfer_rate',
  'cash_transfer_rate',
]);

export const loyaltyProgramTypeSchema = z.enum([
  'points_transfer',
  'crypto',
  'cashback',
  'sustainability',
  'voucher',
]);
export type LoyaltyProgramType = z.infer<typeof loyaltyProgramTypeSchema>;

// base schema
export const transferRateSchema = z.object({
  id: z.string(),
  type: z.string(),
  loyaltyCurrencyId: z.string(),
  denomination: z.number(),
  targetDenomination: z.number(),
  minimumTransfer: z.number(),
  maximumTransfer: z.number(),
  exchangeRate: z.number(),
  targetMinimumTransfer: z.number(),
  targetMaximumTransfer: z.number(),
});

export type TransferRate = z.infer<typeof transferRateSchema>;

export const loyaltyCurrencySchema = z.object({
  id: z.string(),
  type: z.string(),
  name: z.string(),
  shortName: z.string(),
});

export type LoyaltyCurrency = z.infer<typeof loyaltyCurrencySchema>;

export const baseLoyaltyProgramCapabilitySchema = z.object({
  id: z.string(),
  type: z.string(),
  detailsUrl: z.string().nullable(),
  logoUrl: z.string(),
  category: z.string(),
  processingDelay: z.number(),
  config: z.object({}),
});

export type BaseLoyaltyProgramCapability = z.infer<
  typeof baseLoyaltyProgramCapabilitySchema
>;

const baseLoyaltyProgramSchema = z.object({
  id: z.string(),
  type: z.literal('loyalty_program'),
  name: z.string(),
  shortName: z.string(),
  category: z.string(),
  description: z.string().optional(),
  nameRequired: z.boolean(),
  transferConnectIdentifier: z.string(),
  active: z.boolean(),
  manualVerificationRequired: z.boolean(),
  autosweepable: z.boolean().optional(),
  sortIndex: z.number(),
  redeemable: z.boolean(),
  loyaltyCurrency: loyaltyCurrencySchema,
  capabilities: z.array(baseLoyaltyProgramCapabilitySchema),
  transferRate: transferRateSchema,
  discountPercentage: z.number().optional(),
});

export type BaseLoyaltyProgram = z.infer<typeof baseLoyaltyProgramSchema>;

// cashback loyalty program schema
export const cashbackLoyaltyProgramCapabilitySchema =
  baseLoyaltyProgramCapabilitySchema.extend({
    config: z.object({}).or(
      z.object({
        cashRedemption: z.object({ requireEnrollment: z.boolean() }),
      }),
    ),
  });

export type CashbackLoyaltyProgramCapability = z.infer<
  typeof cashbackLoyaltyProgramCapabilitySchema
>;
export const cashbackLoyaltyProgramSchema = baseLoyaltyProgramSchema.extend({
  category: z.enum(['cashback_to_tenant', 'cashback_to_platform']),
  logoUrl: z.string(),
  capabilities: z.array(cashbackLoyaltyProgramCapabilitySchema),
});

export type CashbackLoyaltyProgram = z.infer<
  typeof cashbackLoyaltyProgramSchema
>;

export const cashbackLoyaltyProgramResponseSchema = z.object({
  data: z.array(cashbackLoyaltyProgramSchema),
  meta: paginationMetaSchema,
});

// frequent traveler loyalty program schema
export const frequentTravelerLoyaltyProgramCapabilitySchema =
  baseLoyaltyProgramCapabilitySchema.extend({
    config: z.object({
      discountPercentage: z.number().optional(),
    }),
    enrollmentUrl: z.string().url(),
    detailsUrl: z.string(),
  });

export type FrequentTravelerLoyaltyProgramCapability = z.infer<
  typeof frequentTravelerLoyaltyProgramCapabilitySchema
>;

export const frequentTravelerLoyaltyProgramSchema =
  baseLoyaltyProgramSchema.extend({
    category: z.enum(['airline', 'hotel']),
    enrollmentUrl: z.string().url(),
    capabilities: z.array(frequentTravelerLoyaltyProgramCapabilitySchema),
  });

export type FrequentTravelerLoyaltyProgram = z.infer<
  typeof frequentTravelerLoyaltyProgramSchema
>;

export const frequentTravelerLoyaltyProgramResponseSchema = z.object({
  data: z.array(frequentTravelerLoyaltyProgramSchema),
  meta: paginationMetaSchema,
});

export type FrequentTravelerLoyaltyProgramResponse = z.infer<
  typeof frequentTravelerLoyaltyProgramResponseSchema
>;

// sustainability loyalty program schema

export const sustainabilityLoyaltyProgramCapabilitySchema =
  baseLoyaltyProgramCapabilitySchema.extend({
    config: z.object({}),
    conditionsUrl: z.string(),
    detailsUrl: z.string(),
  });

export type SustainabilityLoyaltyProgramCapability = z.infer<
  typeof sustainabilityLoyaltyProgramCapabilitySchema
>;

export const sustainabilityLoyaltyProgramSchema =
  baseLoyaltyProgramSchema.extend({
    category: z.literal('sustainability'),
    conditionsUrl: z.string(),
    capabilities: z.array(sustainabilityLoyaltyProgramCapabilitySchema),
  });

export type SustainabilityLoyaltyProgram = z.infer<
  typeof sustainabilityLoyaltyProgramSchema
>;

export const sustainabilityLoyaltyProgramResponseSchema = z.object({
  data: z.array(sustainabilityLoyaltyProgramSchema),
  meta: paginationMetaSchema,
});

// crypto loyalty Program

export const cryptoLoyaltyProgramCapabilitySchema =
  baseLoyaltyProgramCapabilitySchema.extend({
    config: z.object({
      fulfillment: z.object({ cryptoCurrencies: z.array(z.string()) }),
    }),
  });

export type CryptoLoyaltyProgramCapability = z.infer<
  typeof cryptoLoyaltyProgramCapabilitySchema
>;

export const cryptoLoyaltyProgramSchema = baseLoyaltyProgramSchema.extend({
  category: z.literal('crypto'),
  capabilities: z.array(cryptoLoyaltyProgramCapabilitySchema),
});

export type CryptoLoyaltyProgram = z.infer<typeof cryptoLoyaltyProgramSchema>;

export const cryptoLoyaltyProgramResponseSchema = z.object({
  data: z.array(cryptoLoyaltyProgramSchema),
  meta: paginationMetaSchema,
});

// voucher loyalty program

export const VoucherLoyaltyProgramCapabilitySchema =
  baseLoyaltyProgramCapabilitySchema.extend({
    config: z.object({}),
    conditionsUrl: z.string(),
    detailsUrl: z.string(),
  });

export type VoucherLoyaltyProgramCapability = z.infer<
  typeof VoucherLoyaltyProgramCapabilitySchema
>;

export const VoucherLoyaltyProgramSchema = baseLoyaltyProgramSchema.extend({
  category: z.literal('voucher'),
  capabilities: z.array(VoucherLoyaltyProgramCapabilitySchema),
});

export type VoucherLoyaltyProgram = z.infer<typeof VoucherLoyaltyProgramSchema>;

export const VoucherLoyaltyProgramResponseSchema = z.object({
  data: z.array(VoucherLoyaltyProgramSchema),
  meta: paginationMetaSchema,
});

// old types

export const loyaltyProgramSchema = z.object({
  name: z.string(),
  shortName: z.string(),
  processingDelay: z.number(),
  category: z.string(),
  description: z.string().optional(),
  detailsUrl: z.string(),
  enrollmentUrl: z.string().url(),
  logoUrl: z.string(),
  conditionsUrl: z.string(),
  nameRequired: z.boolean(),
  transferConnectIdentifier: z.string(),
  active: z.boolean(),
  manualVerificationRequired: z.boolean(),
  autosweepable: z.boolean().optional(),
  sortIndex: z.number(),
  benefitCapability: z.null(),
  transferRate: z.object({
    loyaltyCurrencyId: z.string(),
    denomination: z.number(),
    targetDenomination: z.number(),
    minimumTransfer: z.number(),
    maximumTransfer: z.number(),
    exchangeRate: z.number(),
    targetMinimumTransfer: z.number(),
    targetMaximumTransfer: z.number(),
    id: z.string(),
    type: transferRateType,
  }),
  loyaltyCurrency: z.object({
    name: z.string(),
    shortName: z.string(),
    denomination: z.number(),
    targetDenomination: z.number(),
    minimumTransfer: z.number(),
    targetMinimumTransfer: z.number(),
    maximumTransfer: z.number(),
    targetMaximumTransfer: z.number(),
    exchangeRate: z.number(),
    id: z.string(),
    type: z.enum(['loyalty_currency']),
  }),
  rewardsProgram: z.null(),
  capabilities: z.array(
    z.object({
      detailsUrl: z.string(),
      logoUrl: z.string(),
      conditionsUrl: z.string().nullable(),
      enrollmentUrl: z.string().url(),
      category: z.string(),
      config: z.object({}),
      id: z.string(),
      type: z.string(),
    }),
  ),
  id: z.string(),
  type: z.string(),
});
export type LoyaltyProgram = z.infer<typeof loyaltyProgramSchema>;

export const loyaltyProgramArraySchema = z.array(loyaltyProgramSchema);
export type LoyaltyProgramArray = z.infer<typeof loyaltyProgramArraySchema>;

export const loyaltyProgramResponseSchema = z.object({
  data: loyaltyProgramArraySchema,
  meta: paginationMetaSchema,
});
export type LoyaltyProgramResponse = z.infer<
  typeof loyaltyProgramResponseSchema
>;
