import * as z from 'zod';

import {
  ancillariesSchema,
  passengerInformationSchema,
} from '@/features/checkout/forms/flights-validation-schema';
import { carSchema } from '../cars/car.schema';
import { customDataSchema, redemptionEarnSchema } from '../common.schema';
import { flightSegmentSchema } from '../flights/prices/prices.schema';
import { flightRule } from '../flights/term-condition';
import {
  breakfastOptionsSchema,
  carDriverSchema,
  carFeeSchema,
  carSearchParamsSchema,
  importantInformationSchema,
  recipientSchema,
} from '../shopping-cart-item.schema';
import { cancellationPolicyContentSchema } from '../travel/cancellation-policy.schema';
import { priceBreakdownSchema } from '../travel/price-breakdown.schema';
import { cashRedemptionOrderItemDataSchema } from './cash-redemption';
import {
  notificationSchema,
  orderItemStatusSchema,
  orderItemTypeEnum,
} from './common';
import {
  giftCardOrderItemDataSchema,
  membershipInformationSchema,
  orderItemCampaignDataSchema,
  orderItemDataSchema,
  pointsTransferOrderItemDataSchema,
} from './order-item-data';

export const orderItemAttributesSchema = z.object({
  userId: z.string(),
  status: orderItemStatusSchema,
  description: z.string(),
  quantity: z.number(),
  cashPaid: z.number(),
  cashAmount: z.number(),
  pointsPaid: z.number(),
  pointsAmount: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
  data: orderItemDataSchema,
});

export const orderItemBaseSchema = z.object({
  id: z.string(),
  type: orderItemTypeEnum,
  // I will re-enable this again
  // but will confirm with sir Duc later
  notifications: z.array(notificationSchema).nullish(),
});

export const giftCardOrderItemSchema = orderItemBaseSchema
  .merge(
    orderItemAttributesSchema.extend({ data: giftCardOrderItemDataSchema }),
  )
  .extend({
    type: orderItemTypeEnum.extract(['gift_card_order_item']),
  });

export const cashRedemptionOrderItemSchema = orderItemBaseSchema
  .merge(
    orderItemAttributesSchema.extend({
      data: cashRedemptionOrderItemDataSchema,
    }),
  )
  .extend({
    type: orderItemTypeEnum.extract(['cash_redemption_order_item']),
  });

export const pointsTransferOrderItemSchema = orderItemBaseSchema
  .merge(
    orderItemAttributesSchema.extend({
      data: pointsTransferOrderItemDataSchema,
    }),
  )
  .extend({
    type: orderItemTypeEnum.extract(['points_transfer_order_item']),
  });

export const sustainabilityOrderItemSchema = orderItemBaseSchema
  .merge(orderItemAttributesSchema)
  .extend({
    type: orderItemTypeEnum.extract(['sustainability_order_item']),
    data: z.object({
      redemptionEarn: redemptionEarnSchema.optional(),
      loyaltyProgramId: z.string(),
      capabilityId: z.string(),
      transferAmount: z.number(),
      productName: z.string(),
      productImageUrl: z.string(),
      loyaltyCurrencyName: z.string(),
      membership: membershipInformationSchema,
      customData: customDataSchema.optional(),
      campaign: orderItemCampaignDataSchema.optional(),
    }),
  });

export const cashbackOrderItemSchema = orderItemBaseSchema
  .merge(orderItemAttributesSchema)
  .extend({
    type: orderItemTypeEnum.extract(['cash_redemption_order_item']),
    data: z.object({
      redemptionEarn: redemptionEarnSchema.optional(),
      loyaltyProgramId: z.string(),
      capabilityId: z.string(),
      cashAmount: z.number(),
      productName: z.string(),
      productImageUrl: z.string(),
      loyaltyCurrencyName: z.string(),
      membership: membershipInformationSchema,
      customData: customDataSchema.optional(),
      campaign: orderItemCampaignDataSchema.optional(),
    }),
  });

export const cryptoOrderItemSchema = pointsTransferOrderItemSchema.extend({
  type: orderItemTypeEnum.extract(['crypto_order_item']),
  data: z.object({
    redemptionEarn: redemptionEarnSchema.optional(),
    loyaltyProgramId: z.string(),
    capabilityId: z.string(),
    transferAmount: z.number(),
    productName: z.string(),
    productImageUrl: z.string(),
    loyaltyCurrencyName: z.string(),
    membership: membershipInformationSchema,
    customData: customDataSchema.optional(),
    campaign: orderItemCampaignDataSchema.optional(),
  }),
});

export const voucherOrderItemSchema = pointsTransferOrderItemSchema.extend({
  type: orderItemTypeEnum.extract(['voucher_order_item']),
  data: z.object({
    redemptionEarn: redemptionEarnSchema.optional(),
    loyaltyProgramId: z.string(),
    capabilityId: z.string(),
    transferAmount: z.number(),
    productName: z.string(),
    productShortName: z.string(),
    productImageUrl: z.string(),
    loyaltyCurrencyName: z.string(),
    membership: membershipInformationSchema,
    customData: customDataSchema.optional(),
    campaign: orderItemCampaignDataSchema.optional(),
  }),
});

export const baseTravelOrderItemSchema = orderItemBaseSchema
  .extend({
    type: orderItemTypeEnum.extract(['travel_booking_order_item']),
    bookingStartAt: z.string(),
    bookingEndAt: z.string(),
    supplierReferenceId: z.string().nullish(),
    data: z.object({
      redemptionEarn: redemptionEarnSchema.optional(),
      productName: z.string(),
      tierId: z.number(),
      bookingKey: z.string(),
      bookingTransactionId: z.string(),
      travelType: z.enum(['flights', 'hotels', 'cars']),
      status: orderItemStatusSchema,
      customData: customDataSchema.optional(),
      campaign: orderItemCampaignDataSchema.optional(),
    }),
  })
  .merge(orderItemAttributesSchema);

const flightOrderItemDataSchema = z.object({
  ancillaries: ancillariesSchema,
  redemptionEarn: redemptionEarnSchema.optional(),
  rules: z.array(flightRule),
  productName: z.string().nullish(),
  productImageUrl: z.string().nullish(),
  locale: z.string(),
  tierId: z.number(),
  currency: z.string(),
  recipient: recipientSchema,
  passengers: z.array(passengerInformationSchema),
  bookingKey: z.string(),
  cabinClass: z.string(),
  flightType: z.enum(['rt', 'ow']),
  travelType: z.literal('flights'),
  allSegments: z.array(flightSegmentSchema).nonempty(),
  paymentMethod: z
    .object({
      id: z.string(),
      saveCard: z.boolean(),
      returnUrl: z.string().optional(),
    })
    .nullish(),
  originSegments: z.array(flightSegmentSchema),
  orderSourceType: z.string(),
  bookingTransactionId: z.string(),
  travelConfirmationId: z.string().nullish(),
  customData: customDataSchema.nullish(),
  campaign: orderItemCampaignDataSchema.optional(),
});

export const flightOrderItemSchema = baseTravelOrderItemSchema.extend({
  data: flightOrderItemDataSchema,
});

export type FlightOrderItemSchema = z.infer<typeof flightOrderItemSchema>;

const hotelOrderItemDataSchema = z.object({
  travelConfirmationId: z.string().optional(),
  redemptionEarn: redemptionEarnSchema.optional(),
  breakfastInfo: breakfastOptionsSchema,
  bookingKey: z.string(),
  bookingTransactionId: z.string(),
  cancellationPolicy: cancellationPolicyContentSchema.nullish(),
  currency: z.string(),
  freeCancellation: z.boolean(),
  guest: z.object({
    email: z.string(),
    phone: z.string(),
    lastName: z.string(),
    firstName: z.string(),
    salutation: z.string(),
    specialRequest: z.string().optional(),
  }),
  heroImageUrl: z.string(),
  hotel: z.object({
    adultCount: z.string(),
    checkin: z.string(),
    checkout: z.string(),
    childCount: z.string(),
    destinationId: z.string(),
    guests: z.string(),
    hotelAddress: z.string(),
    hotelId: z.string(),
    hotelName: z.string(),
    roomCount: z.string(),
    roomDescription: z.string(),
  }),
  importantInformation: importantInformationSchema.nullish(),
  locale: z.string(),
  orderSourceType: z.string(),
  productName: z.string().nullish(),
  recipient: z.object({
    email: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    phoneNumber: z.string(),
    salutation: z.string().optional(),
  }),
  status: orderItemStatusSchema.nullish(),
  tierId: z.number(),
  travelType: z.literal('hotels'),
  refundCashAmountInCents: z.number().nullish(),
  refundPointsAmount: z.number().nullish(),
  originalMetadata: z
    .object({
      name: z.null(),
      city: z.string(),
      state: z.null(),
      country: z.string(),
    })
    .optional(),
  priceBreakdown: priceBreakdownSchema,
  customData: customDataSchema.optional(),
  campaign: orderItemCampaignDataSchema.optional(),
});

export const hotelOrderItemSchema = baseTravelOrderItemSchema.extend({
  data: hotelOrderItemDataSchema,
});

export type HotelOrderItem = z.infer<typeof hotelOrderItemSchema>;

const carOrderItemDataSchema = z.object({
  redemptionEarn: redemptionEarnSchema.partial().nullish(),
  travelConfirmationId: z.string().nullish(),
  productName: z.string().nullish(),
  driver: carDriverSchema,
  car: carSchema.pick({
    name: true,
    aircon: true,
    baggage: true,
    charges: true,
    category: true,
    imageUrl: true,
    passengers: true,
    supplierName: true,
    pricedCoverages: true,
    transmissionType: true,
    supplierImageUrl: true,
  }),
  locale: z.string(),
  tierId: z.number(),
  currency: z.string(),
  bookingKey: z.string(),
  travelType: z.literal('cars'),
  paymentMethod: z.object({ id: z.string(), saveCard: z.boolean() }).nullish(),
  cashPaidInUsd: z.number(),
  orderSourceType: z.string(),
  location: z.object({
    supplierLat: z.string(),
    supplierLng: z.string(),
    supplierAddress: z.string(),
    dropoffSupplierLat: z.string(),
    dropoffSupplierLng: z.string(),
    dropoffSupplierAddress: z.string(),
  }),
  recipient: z.object({
    salutation: z.string().optional(),
    email: z.string(),
    lastName: z.string(),
    firstName: z.string(),
    phoneNumber: z.string(),
  }),
  bookingTransactionId: z.string(),
  search: carSearchParamsSchema,
  fees: z.array(carFeeSchema).nullish(),
  customData: customDataSchema.optional(),
  campaign: orderItemCampaignDataSchema.optional(),
});

export const carOrderItemSchema = baseTravelOrderItemSchema.extend({
  data: carOrderItemDataSchema,
});

export const travelOrderItemSchema = baseTravelOrderItemSchema.extend({
  data: z.discriminatedUnion('travelType', [
    flightOrderItemDataSchema,
    hotelOrderItemDataSchema,
    carOrderItemDataSchema,
  ]),
});

export type TravelOrderItemSchema = z.infer<typeof travelOrderItemSchema>;

export type CarOrderItemDriverSchema = z.infer<typeof carDriverSchema>;

export type CarOrderItemSchema = z.infer<typeof carOrderItemSchema>;

const merchandiseOrderItemDataSchema = z.object({
  sku: z.string().nullable(),
  amount: z.number(),
  brand: z.string(),
  currency: z.string(),
  quantity: z.string(),
  supplier: z.string().nullable(),
  supplierPrice: z.string().nullable().optional(),
  recipient: z.object({
    email: z.string(),
    address: z.object({
      city: z.string(),
      state: z.string(),
      line1: z.string(),
      line2: z.string().optional(),
      postalCode: z.string(),
      countryCode: z.string(),
    }),
    fullName: z.string(),
    companyName: z.string().optional(),
    phoneNumber: z.string(),
  }),
  cashAmount: z.number(),
  productName: z.string(),
  displayValue: z.number().nullable(),
  merchandiseId: z.string().nullable(),
  orderSourceType: z.string(),
  pointsPercentage: z.number(),
  productImageUrl: z.string(),
  externalSupplierOrderReference: z.string().nullable(),
  externalSupplierOrderItemReference: z.string().nullable(),
  fulfillmentData: z
    .object({
      courier: z.string().nullable().optional(),
      shipmentStatus: z.enum(['po_received', 'po_shipped', 'shipped']),
      shippedAt: z.string(),
      trackingId: z.string(),
      updatedAt: z.string(),
    })
    .optional(),
  customData: customDataSchema.optional(),
  campaign: orderItemCampaignDataSchema.optional(),
});

export const merchandiseOrderItemSchema = orderItemBaseSchema
  .merge(orderItemAttributesSchema)
  .extend({
    type: orderItemTypeEnum.extract(['merchandise_order_item']),
    data: merchandiseOrderItemDataSchema,
  });

export type MerchandiseOrderItemSchema = z.infer<
  typeof merchandiseOrderItemSchema
>;

// this can't be discriminated because it's flightOrderItem and hotelOrderItem share the same 'type' attribute
export const orderItemSchema = z.discriminatedUnion('type', [
  giftCardOrderItemSchema,
  sustainabilityOrderItemSchema,
  cashbackOrderItemSchema,
  pointsTransferOrderItemSchema,
  cryptoOrderItemSchema,
  travelOrderItemSchema,
  voucherOrderItemSchema,
  merchandiseOrderItemSchema,
]);

export const redeemAgainItemSchema = z.discriminatedUnion('type', [
  giftCardOrderItemSchema,
  sustainabilityOrderItemSchema,
  cashbackOrderItemSchema,
  pointsTransferOrderItemSchema,
  cryptoOrderItemSchema,
  voucherOrderItemSchema,
]);
export type PointsTransferOrderItem = z.infer<
  typeof pointsTransferOrderItemSchema
>;

export type RedeemAgainOrderItem = z.infer<typeof redeemAgainItemSchema>;
export type OrderItem = z.infer<typeof orderItemSchema>;
export type TravelItem = z.infer<typeof orderItemSchema>;

export type GiftCardOrderItem = z.infer<typeof giftCardOrderItemSchema>;

export const isFlightOrderItem = (
  orderItem: OrderItem | undefined,
): orderItem is FlightOrderItemSchema => {
  return (
    orderItem?.type === 'travel_booking_order_item' &&
    orderItem?.data.travelType === 'flights'
  );
};

export const isHotelOrderItem = (
  orderItem: OrderItem | undefined,
): orderItem is HotelOrderItem => {
  return (
    orderItem?.type === 'travel_booking_order_item' &&
    orderItem?.data.travelType === 'hotels'
  );
};

export const isCarOrderItem = (
  orderItem: OrderItem | undefined,
): orderItem is CarOrderItemSchema => {
  return (
    orderItem?.type === 'travel_booking_order_item' &&
    orderItem?.data.travelType === 'cars'
  );
};

export const isPointsTransferOrderItem = (
  orderItem: OrderItem | undefined,
): orderItem is PointsTransferOrderItem => {
  return orderItem?.type === 'points_transfer_order_item';
};

export const isMerchandiseOrderItem = (
  orderItem: OrderItem | undefined,
): orderItem is MerchandiseOrderItemSchema => {
  return orderItem?.type === 'merchandise_order_item';
};
