import ListingProductSkeleton from '@/components/listing-product-skeleton';
import { cn } from '@/utils/tailwind';
import { memo } from 'react';

function Component({ title }: { title: string }) {
  const maxItems = 5;

  return (
    <div className="my-6 container-responsive lg:my-8">
      <h2 className="mb-6 font-heading text-2xl-bold md:text-3xl-bold lg:mb-8">
        {title}
      </h2>
      <div
        className={cn(
          'flex flex-col gap-4 overflow-auto lg:flex-row',
          '[&>img]:h-[115px] [&>img]:w-[184px]',
        )}
      >
        {Array.from({ length: maxItems }).map((_, idx) => (
          <ListingProductSkeleton
            key={idx}
            className="basis-full lg:min-w-[200px] lg:max-w-[230px]"
          />
        ))}
      </div>
    </div>
  );
}

export const RecommendedSkeleton = memo(Component);
